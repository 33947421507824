import React from 'react';
import { Link } from 'react-router-dom';

function CardItem(props) {
  return (
    <>
      <li className='cards__item'>
        <Link className='cards__item__link' to={props.path}>
          <img
            id={props.id}
            className='cards__item__img'
            alt=""
            src={props.src}
          />
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.header}</h5>
            <h5 className='cards__item__text'>{props.text}</h5>
            <h5 className='cards__item__text'>{props.caps}</h5>
            <h5 className='cards__item__text'>{props.body}</h5>
            <h5 className='cards__item__text'>{props.gram}</h5>
          </div>
        </Link>
      </li>
    </>
  );
}

export default CardItem;